@import '../../../../../assets/_generic/all';

.button {
  @include fonts(16, 24);
  font-weight: 700;
  font-family: var(--font-family);
  color: var(--color-black);
  background-color: var(--color-primary);
  border: none;
  padding: 0 20px !important;
  height: 35px !important;
  border-radius: 2px;

  &:focus {
    border: none;
  }
  
  &:hover {
    color: var(--color-black) !important;
  }
  
  &:disabled {
    background-color: var(--color-disabled);
    color: var(--color-black);
  }
}


