.sideBar {
  position: fixed!important;
  left: 0;
  top: 0;
  bottom: 0;
  height: calc(100vh - 48px);;
  overflow: auto;

  &Logo {
    display: flex;
    justify-content: left;
    align-items: left;
    height: 64px;
    margin: 16px 0 16px 16px;
    img {
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
    }
  }
}
