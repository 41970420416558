:root {
  --color-white: #ffffff;
  --color-black: #000000;

  --color-primary-100: #ffcf03;
  --color-primary-200: #f9d860;
  --color-primary-300: #e1be40;
  --color-primary-400: #b9940f;
  --color-primary-500: #836702;

  --color-secondary-100: #8afffb;
  --color-secondary-200: #27d0cb;
  --color-secondary-300: #139894;
  --color-secondary-400: #006461;
  --color-secondary-500: #003534;

  --color-info-100: #cafdf5;
  --color-info-200: #61f3f3;
  --color-info-300: #00b8d9;
  --color-info-400: #006c9c;
  --color-info-500: #003768;

  --color-success-100: #d8fbde;
  --color-success-200: #86e8ab;
  --color-success-300: #36b37e;
  --color-success-400: #1b806a;
  --color-success-500: #0a5554;

  --color-warning-100: #fff5cc;
  --color-warning-200: #ffd666;
  --color-warning-300: #ffab00;
  --color-warning-400: #b76e00;
  --color-warning-500: #7a4100;

  --color-error-100: #ffe9d5;
  --color-error-200: #ffac82;
  --color-error-300: #ff5630;
  --color-error-400: #b71d18;
  --color-error-500: #7a0916;

  --color-grey-100: #f9fafb;
  --color-grey-200: #f4f6f8;
  --color-grey-300: #dfe3e8;
  --color-grey-400: #c4cdd5;
  --color-grey-500: #919eab;
  --color-grey-600: #637381;
  --color-grey-700: #454f5b;
  --color-grey-800: #212b36;
  --color-grey-900: #161c24;
}

:root {
  --color-primary: var(--color-primary-100);
  --color-secondary: var(--color-secondary-300);
  --color-info: var(--color-info-300);
  --color-success: var(--color-success-300);
  --color-warning: var(--color-warning-300);
  --color-error: var(--color-error-300);
}
