@import '../../../assets/_generic/all';

.dashboardContent {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 18px;
  position: relative;
  padding-top: 110px;
  height: 100%;


  .header {
    color: var(--color-white);
    border-bottom: 1px dashed var(--color-white);
    padding-bottom: 24px;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;

    .title {
      font-size: 18px;
      line-height: 24px;
      min-width: 125px;
      display: inline-block;
      margin-right: 25px;

      @media only screen and (max-width: 1900px) {
        font-size: 16px;
        line-height: 22px;
      }
    }

    .titleDescription {
      font-size: 20px;
      line-height: 24px;

      @media only screen and (max-width: 1900px) {
        font-size: 18px;
      }
    }

    .referralWrapper {
      text-transform: uppercase;
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 25px;
      }
    }
  }

  .bodyLine {
    height: 2px;
    width: 100%;

    svg {
      margin-top: 24px;
      width: 100%;
      color: var(--color-white);
    }
  }

  .body {
    margin-top: 24px;
    display: flex;
    overflow-y: auto;
    height: 100%;

    .sideContent {
      width: 35%;

      .sideBlock {
        width: 100%;
        padding: 24px 20px;
        text-align: center;
        background-color: var(--color-primary);
        border-radius: 4px;
        margin-bottom: 24px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media only screen and (max-width: 1900px) {
          padding: 15px;
        }

        .title {
          font-weight: 700;
          font-size: 20px;
          line-height: 26px;
          color: #231f20;
          line-height: normal;

          @media only screen and (max-width: 1900px) {
            font-size: 18px;
            line-height: 24px;
          }
        }

        .number {
          font-family: var(--font-family-montreal);
          font-size: 32px;
          letter-spacing: 1px;

          @media only screen and (max-width: 1900px) {
            font-size: 28px;
          }
        }
      }
    }

    .mainContent {
      width: 65%;
      margin-left: 24px;
    }
  }
}

.copyBtn {
  display: flex;
  align-items: center;
  text-transform: none;

  cursor: pointer;
  @include fonts(20, 24);
  color: #fff;
  font-family: var(--font-family);
  font-weight: 400;

  :global(.ant-typography-copy) {
    color: var(--color-primary);
    font-size: 20px;

    &:active,
    &:focus,
    &:hover {
      color: var(--color-primary);
    }
  }
}
