.accordionWrapper {
  font-size: 16px;
  font-family: var(--font-family);
  border: none;

  .panelWrapper {
    border: none;

    :global(.ant-collapse-header) {
      font-size: 24px;
      line-height: 32px;
      padding: 18px 24px 18px 40px;
      background: var(--color-primary);
      border-radius: 4px !important;
      margin-bottom: 32px;

      @media only screen and (max-width: 1900px) {
        font-size: 20px;
        line-height: 28px;
      }

      :global(.ant-collapse-header-text) {
        .panelHeader {
          display: flex;
          justify-content: space-between;

          .body1 {
            &:first-child {
              margin-left: 20px;
            }
          }

          div {
            &:nth-child(2) {
              display: flex;
              align-items: center;

              svg {
                width: 28px;
                height: 30px;
                transform: rotate(-90deg);
                transition: transform 0.2s linear;

                @media only screen and (max-width: 1900px) {
                  width: 20px;
                  height: 22px;
                }
              }
            }
          }
        }
      }

      :global(.ant-collapse-expand-icon) {
        display: none;
      }
    }

    :global(.ant-collapse-content) {
      background: initial;
      border: none;
      color: var(--color-white);
    }

    .contentWrapper {
      margin: 0 auto 30px;
      display: flex;
      max-width: 90%;

      .imgWrapper {
        flex: 0 0 40%;
        max-width: 40%;

        img {
          max-width: 100%;
          object-fit: contain;
        }
      }

      .content {
        margin: 50px 0 84px 32px;
        color: var(--color-white);
        flex: 0 0 60%;
        max-width: 60%;

        .title {
          @include fonts(20, 24);
          margin: 0 0 20px;
          font-weight: 600;

          @media only screen and (max-width: 1900px) {
            @include fonts(18, 24);
          }
        }

        .descriptionWrapper {
          margin-bottom: 20px;

          .description {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 30px;
          }
        }

        .downloadLinkWrapper {
          .title {
            margin-bottom: 8px;
          }

          .link {
            a {
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: var(--color-primary-100);
              word-wrap: break-word;

              &:active,
              &:hover,
              &:visited {
                color: var(--color-primary-100);
              }

              @media only screen and (max-width: 1900px) {
                font-size: 15px;
              }
            }

            .downloadLink {
              display: inline-block;
              width: auto;
              min-width: 200px;
              font-weight: 700;
              padding: 4px 20px !important;
              border-radius: 2px;

              @media only screen and (max-width: 1900px) {
                font-size: 15px;
              }
            }
          }
        }
      }
    }

    span {
      color: var(--color-black);
    }

    :global(.ant-collapse-content-active) {
      .ant-collapse-content-box {
        background: initial;
      }
    }

    :global(.ant-collapse-content-box) {
      padding: 0;
    }
  }

  :global(.ant-collapse-item-active) {
    svg {
      transform: rotate(0) !important;
    }
  }

  span {
    color: var(--color-black);
  }
}
