@import '../../pages/App.module.scss';

.addCollectionContainer {
  width: 100%;
  button {
    width: 100%;
  }
}

.collectionActionContainer {
  width: 30%;
}