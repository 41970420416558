@import '../../../assets/_generic/all';
@import '../../../assets/_shared/custom_accordion';

.otherInfo {
  display: flex;
  column-gap: 20px;
  justify-content: space-between;
  margin: 20px 0 10px;

  p {
    flex: 1;
    background-color: var(--color-primary);
    color: var(--color-black);
    padding: 15px;
    border-radius: 6px;
    text-align: center;
    @include fonts(18, 24);
    font-weight: 700;

    @media only screen and (max-width: 1900px) {
      @include fonts(16, 24);
    }

    span {
      display: block;
      color: var(--color-black);
      font-family: var(--font-family-montreal);
      font-weight: 400;
      @include fonts(30, 34);

      @media only screen and (max-width: 1900px) {
        @include fonts(24, 28);
      }
    }
  }
}

.reviewButton {
  max-width: 200px;

  button {
    border-radius: 2px !important;
  }
}

.title {
  @include fonts(20, 24);
  margin: 0 0 24px;
}

.submissionWrapper {
  padding: 0 0 30px;
}

.submissionList {
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  padding: 0;
  margin: 0;

  li {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    padding: 10px;

    h5 {
      width: 100%;
      margin: 0;
      font-size: 18px;
      line-height: 24px;
      background-color: rgba(255, 255, 255, 0.1);
      padding: 4px 12px;
      font-family: var(--font-family);
      margin: 0 0 10px;
      @include centerer;
      justify-content: space-between;
      svg{
        cursor: pointer;
      }
    }
  }
}

.backButton {
  cursor: pointer;
  margin-bottom: 30px;

  div {
    font-size: 16px;
    font-weight: 400;
    color: var(--color-primary);
  }
}

.backIcon {
  font-size: 16px;
  margin-top: -2px;
}

// Preview
.proofView {
  display: block;

  &Image {
    width: 165px;
    height: 110px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    overflow: hidden;

    img {
      width: 165px !important;
      height: 110px !important;
      object-fit: contain;
    }
  }

  &Text {
    p {
      color: var(--color-white);
      font-size: 14px;
      line-height: 24px;
      font-family: var(--font-family);
    }
  }

  &Video {
    width: 240px;
    height: 90px;
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;

    &Player {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }

  &Audio {
    audio {
      width: 100%;
    }
  }
}

.scrollableContent {
  overflow-y: auto;
  height: 100%;
  padding-right: 2px;
}