@mixin Smooth($x) {
  transition: $x all ease-in-out;
}

@mixin animate($animation, $duration, $method, $times) {
  animation: $animation $duration $method $times;
}

@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}

@mixin displayBlock($width) {
  width: $width;
  display: block;
}

@mixin awesomeScrollbar {
  overflow: auto;

  ::-webkit-scrollbar {
    width: 2px;
    height: 50px;
  }


  ::-webkit-scrollbar-track {
    background: var(--color-black);
  }


  ::-webkit-scrollbar-thumb {
    background: var(--color-black);
  }
}

@mixin shineAnimate($opacity) {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, $opacity) 50%,
      rgba(255, 255, 255, 0) 100%);
  animation: shine 4s ease-in-out infinite;
}

@keyframes shine {
  0% {
    left: -50%;
  }

  100% {
    left: 100%;
  }
}

@mixin firefox-only {
  @at-root {
    @-moz-document url-prefix() {
      & {
        @content;
      }
    }
  }
}

@mixin safari-only {
  @at-root {
    @supports (-webkit-hyphens: none) {
      & {
        @content;
      }
    }
  }
}

@mixin AnimatedGroupLink($color) {
  position: relative;
  overflow: hidden;

  padding: 0;
  margin: 0;
  background: transparent;
  border: 0;
  border-radius: 0;

  font-family: var(--font-family);
  font-size: 15px;
  color: $color;
  vertical-align: middle;
  display: inline-block;

  span {
    color: var(--yellow);
  }

  &:first-child {
    margin-right: 20px;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $color;
    transition: transform 350ms linear;
    transform: translate3d(-100%, 0, 0);
  }

  &:active,
  &:hover,
  &:focus {
    color: $color;
  }

  &:hover::after {
    transform: translate3d(0, 0, 0);
  }
}

@mixin resetButtonStyle() {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
}

// === RESPOND ABOVE ===
// @include respond-above(small) {}
@mixin respond-above($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @media (min-width: $breakpoint-value) {
      @content;
    }
  }

  @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

// === RESPOND BELOW ===
// @include respond-below(small) {}
@mixin respond-below($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
  }

  @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

// === RESPOND BETWEEN ===
// @include respond-between(small, medium);
@mixin respond-between($lower, $upper) {
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }
  }

  @else {
    @if (map-has-key($breakpoints, $lower)==false) {
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    @if (map-has-key($breakpoints, $upper)==false) {
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}

@mixin pc() {
  @media (min-width: calc($desktopSmall + 1px)) {
    @content;
  }
}

@mixin tb() {
  @media (min-width: calc($mobile + 1px)) and (max-width: $desktopSmall) {
    @content;
  }
}

@mixin mb() {
  @media (max-width: $mobile) {
    @content;
  }
}

@mixin tb-up() {
  @media (min-width: calc($tablet + 1px)) {
    @content;
  }
}

@mixin tb-down() {
  @media (max-width: $tablet) {
    @content;
  }
}

@mixin fonts($fontSizePx: false, $lineHeightPx: false, $letterSpacingPx: false, $cutLineSpace: false) {
  @if ($fontSizePx) {
    font-size: toRem(($fontSizePx + px));
  }

  @if ($lineHeightPx) {
    line-height: calc($lineHeightPx / $fontSizePx);
  }

  @if ($letterSpacingPx) {
    letter-spacing: calc($letterSpacingPx / $fontSizePx) + em;
  }

  @if ($cutLineSpace) {

    &::before,
    &::after {
      display: block;
      overflow: hidden;
      content: '';
    }

    $_marginVal: toRem((($lineHeightPx - $fontSizePx) * -0.5));

    &::before {
      margin-bottom: toRem(($_marginVal + px));
    }

    &::after {
      margin-top: toRem(($_marginVal + px));
    }
  }
}

@mixin centerer {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin limit-line-number($number: 1) {
  display: -webkit-box;
  -webkit-line-clamp: $number;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

@mixin placeholder() {
  ::-webkit-input-placeholder {
    /* Edge */
    @content;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    @content;
  }

  ::placeholder {
    @content;
  }
}

@mixin margin-auto() {
  .m-auto {
    margin: auto;
  }

  .my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .mt-auto {
    margin-top: auto;
  }

  .mb-auto {
    margin-bottom: auto;
  }

  .ml-auto {
    margin-left: auto;
  }

  .mr-auto {
    margin-right: auto;
  }
}

@mixin width() {
  .w-min-content {
    width: min-content;
  }

  .w-max-content {
    width: max-content;
    max-width: max-content;
  }

  .w-full {
    width: 100%;
  }

  .w-half {
    width: 50%;
  }

  .w-auto {
    width: auto;
  }

  .w-100 {
    width: 100% !important;
  }

  .h-100 {
    height: 100% !important;
  }

  .w-fit-content {
    width: fit-content;
  }
}

// === CONVERT PXREM ===
@function strip-units($number) {
  @return calc($number / ($number * 0 + 1px));
}

@function convertPxToRem($px) {
  @return 0rem+calc(strip-units($px) / 16);
}

@function toRem($values) {
  $px-values: ();
  $rem-values: ();

  @if type-of($values) !='list' {
    $values: join((), $values);
  }

  // Loop through the $values list:
  @each $value in $values {
    @if type-of($value)==number and (unitless($value) or unit($value)==px) {
      $rem-values: join($rem-values, convertPxToRem($value));
    }

    @else {
      // no conversion:
      $rem-values: join($rem-values, $value);
    }
  }

  // don't add media query if it's not needed:
  @if $px-values !=$rem-values {
    // @media only all {
    @return $rem-values;
    // }
  }

  @return $px-values;
}

@mixin fonts($fontSizePx: false, $lineHeightPx: false, $letterSpacingPx: false, $cutLineSpace: false) {
  @if ($fontSizePx) {
    font-size: toRem(($fontSizePx + px));
  }

  @if ($lineHeightPx) {
    line-height: calc($lineHeightPx / $fontSizePx);
  }

  @if ($letterSpacingPx) {
    letter-spacing: ($letterSpacingPx / $fontSizePx) + em;
  }

  @if ($cutLineSpace) {

    &::before,
    &::after {
      display: block;
      overflow: hidden;
      content: '';
    }

    $_marginVal: toRem((($lineHeightPx - $fontSizePx) * -0.5));

    &::before {
      margin-bottom: toRem(($_marginVal + px));
    }

    &::after {
      margin-top: toRem(($_marginVal + px));
    }
  }
}

@mixin base-spacing($space) {
  .m-#{$space} {
    margin: #{$space}px;
  }

  .mx-#{$space} {
    margin-left: #{$space}px;
    margin-right: #{$space}px;
  }

  .my-#{$space} {
    margin-top: #{$space}px;
    margin-bottom: #{$space}px;
  }

  .ml-#{$space} {
    margin-left: #{$space}px;
  }

  .mr-#{$space} {
    margin-right: #{$space}px;
  }

  .mb-#{$space} {
    margin-bottom: #{$space}px;
  }

  .mt-#{$space} {
    margin-top: #{$space}px;
  }

  .p-#{$space} {
    padding: #{$space}px;
  }

  .px-#{$space} {
    padding-left: #{$space}px;
    padding-right: #{$space}px;
  }

  .py-#{$space} {
    padding-top: #{$space}px;
    padding-bottom: #{$space}px;
  }

  .pl-#{$space} {
    padding-left: #{$space}px;
  }

  .pr-#{$space} {
    padding-right: #{$space}px;
  }

  .pb-#{$space} {
    padding-bottom: #{$space}px;
  }

  .pt-#{$space} {
    padding-top: #{$space}px;
  }
}

@mixin width() {
  .w-min-content {
    width: min-content;
  }

  .w-max-content {
    width: max-content;
  }

  .w-full {
    width: 100%;
  }

  .w-half {
    width: 50%;
  }

  .w-auto {
    width: auto;
  }

  .w-100 {
    width: 100%;
  }

  .w-100-vw {
    width: 100vh;
  }

  .w-fit-content {
    width: fit-content;
  }
}

@mixin height() {
  .h-min-content {
    height: min-content;
  }

  .h-max-content {
    height: max-content;
  }

  .h-full {
    height: 100%;
  }

  .h-half {
    height: 50%;
  }

  .h-auto {
    height: auto;
  }

  .h-100 {
    height: 100%;
  }

  .h-100-vw {
    height: 100vh;
  }

  .h-fit-content {
    height: fit-content;
  }
}

@mixin flex-order($space) {
  .order-#{$space} {
    order: $space;
  }
}

@mixin base-text-size($size) {
  .text-#{$size} {
    font-size: #{$size}px;
  }
}

@mixin base-font-weight($size) {
  .font-weight-#{$size} {
    font-weight: #{$size};
  }
}