$spaceamounts: (-1, 0, 1);

@each $space in $spaceamounts {
  @include flex-order($space);
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-col-reverse-tb-down {
  @include tb-down {
    flex-direction: column-reverse;
  }
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-1 {
  flex: 1;
}

// FLEX-WRAP

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

// Justify-content

.justify-between {
  justify-content: space-between;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-around {
  justify-content: space-around;
}

// Align-items

.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
}

.items-start {
  align-items: flex-start;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

// Align-self
.align-self-start {
  align-self: start;
}

.align-self-stretch {
  align-self: stretch;
}

.align-self-start {
  align-self: start;
}

.align-self-end {
  align-self: end;
}

.align-self-center {
  align-self: center;
}

.float-start {
  float: left;
}

.float-end {
  float: right;
}
