.color-primary {
  color: var(--color-primary);
}

.color-white {
  color: var(--color-white);
}

.color-dark {
  color: var(--color-dark);
}

.text-left{
  text-align: left;
}

.text-right{
  text-align: right;
}

.text-center{
  text-align: center;
}

.text-underline{
  text-decoration: underline;
}

.text-link{
  cursor: pointer;
}

.text-uppercase{
  text-transform: uppercase;
}