.awesome-scrollbar {
  // @include awesomeScrollbar();

  overflow: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 4px;
    transition: all ease-in-out 500ms;
  }

  &::-webkit-scrollbar-thumb {
    opacity: 0;
    -webkit-transition: opacity 0.5s 0.5s ease-out;
    transition: opacity 0.5s 0.5s ease-out;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: var(--color-primary);
      opacity: 1;
      -webkit-transition-delay: 0s;
      transition-delay: 0s;
    }
  }
}
