// Shard styles
.pageHeader {
  background-color: var(--color-primary);
  padding: 16px 40px 18px;
  border-radius: 6px;
  
  display: flex;
  align-items: center;

  @media only screen and (max-width: 1900px) {
    padding: 16px 25px 18px;
  }

  h5 {
    font-size: 28px;
    line-height: 32px;
    font-weight: 400;

    margin: 0;

    @media only screen and (max-width: 1900px) {
      font-size: 22px;
      line-height: 26px;
    }

    strong {
      display: inline-block;
      padding-left: 25px;
      font-size: 32px;
      line-height: 32px;
      font-weight: 400;
      font-family: var(--font-family-montreal);

      @media only screen and (max-width: 1900px) {
        font-size: 26px;
        line-height: 26px;
        padding-left: 20px;
      }
    }
  }

  h6 {
    font-size: 21px;
    line-height: 26px;
    font-weight: 700;

    margin: 0;

    @media only screen and (max-width: 1900px) {
      font-size: 18px;
      line-height: 24px;
    }

    strong {
      display: inline-block;
      padding-left: 25px;
      font-size: 32px;
      line-height: 40px;
      font-weight: 400;
      font-family: var(--font-family-montreal);

      @media only screen and (max-width: 1900px) {
        font-size: 24px;
        line-height: 30px;
        padding-left: 20px;
      }
    }
  }
}
