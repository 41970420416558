.table {
  color: var(--color-white);

  :global(.ant-table) {
    background-color: initial;
  }

  :global(.ant-table-placeholder) {
    background-color: transparent !important;
  }

  :global(.ant-table-placeholder .ant-empty-image) ellipse,
  :global(.ant-table-placeholder .ant-empty-image) path {
    fill: #333;
  }

  :global(.ant-empty-description) {
    color: #fff;
  }

  table {
    border-collapse: separate;
    border-spacing: 8px 16px;
    margin: -16px 5px;

    thead {
      tr {
        th {
          font-weight: 600 !important  ;
          border: 0.25px solid rgb(214, 214, 214, 0.5) !important;
          border-radius: 4px !important;
          background-color: #231f20 !important;
          margin-right: 1rem;
          color: var(--color-white) !important;
          text-align: center !important;
          height: 45px;
          padding: 0 !important;
          font-size: 18px;
          font-family: var(--font-family);

          @media only screen and (max-width: 1900px) {
            font-size: 15px;
          }

          &::before {
            display: none;
          }

          &:first-child {
            border-start-start-radius: 4px !important;
          }

          &:last-child {
            border-start-end-radius: 4px !important;
          }
        }
      }
    }
    tbody {
      border: none;

      tr {
        &:hover {
          background: rgba(255, 255, 255, 0.1);

          td {
            background: rgba(255, 255, 255, 0.1) !important;
          }
        }

        td {
          background: rgba(255, 255, 255, 0.1);
          height: 38px;
          padding: 0 !important;
          color: var(--color-white);
          font-size: 18px;
          font-weight: 400;
          font-family: var(--font-family);
          text-align: center;
          border: none !important;

          @media only screen and (max-width: 1900px) {
            font-size: 14px;
          }

          :global(.ant-table-cell-row-hover) {
            background-color: rgba(255, 255, 255, 0.1);
          }

          &:hover {
            background: rgba(255, 255, 255, 0.1);
          }
        }
      }
    }
  }
}
