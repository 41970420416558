@import '../../../assets/_generic/all';

.main {
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/images/collaborator-login-bg.webp');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @include centerer;

  @include mb {
    background-image: url('../../../assets/images/collaborator-login-bg-mobile.jpg');
  }
}

.container {
  @include centerer;

  @media only screen and (max-width: 767px) {
    padding: 24px;
  }
}

.title {
  @include fonts(46, 46);
  text-align: center;
  color: var(--color-white);
  font-family: var(--font-family-montreal);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.55);

  &Mobile {
    display: none;
  }

  @media only screen and (max-width: 767px) {
    @include fonts(32, 32);

    display: none;

    &Mobile {
      display: block;
    }
  }
}

.terms,
.link {
  a {
    color: var(--color-white);
    background-color: initial;
    border: none;
    padding: 0;
    cursor: pointer;
  }
}

.terms {
  color: var(--color-white);
  margin-top: 5px;
  cursor: default !important;

  a {
    text-decoration: underline;
  }

  :global {
    .ant-checkbox + span {
      color: var(--color-white);
    }

    .ant-checkbox-inner::after {
      border-color: var(--color-white);
    }
  }
}

.logo {
  margin-bottom: 10px;
  width: 85px;
}

.formWrapper {
  width: 100%;
  max-width: 445px;
  margin: 0 auto;

  input {
    padding: 13px 20px;
    font-size: 18px;
    line-height: 24px;
  }
}

.loginButton {
  background-color: var(--color-primary);
  padding: 12px 20px;
  font-size: 18px;
  height: 50px !important;
  border-radius: 4px !important;
}

.blockerMessage {
  display: none;

  @media only screen and (max-width: 767px) {
    display: block;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 991;

    background-color: rgba(0, 0, 0, 0.1);

    p {
      align-items: center;
      background-color: rgba(0, 0, 0, 0.9);
      border: 0.5px solid var(--color-white);
      border-radius: 20px;
      color: var(--color-white);
      display: flex;
      font-family: var(--font-family);
      font-size: 22px;
      justify-content: center;
      line-height: 1.2727272727;
      margin: auto;
      min-height: 220px;
      padding: 40px 20px;
      position: absolute;
      text-align: center;
      top: 50%;
      width: 95%;
      left: 0;
      right: 0;
      z-index: 999;
      transform: translateY(-50%);
    }
  }
}
