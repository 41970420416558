:root {
  --font-family: 'Quicksand', sans-serif;
  --font-family-bold: 'QuicksandBold';
  --font-family-montreal: 'MontrealHeavyTwo';
  --font-family-quicksand-book: 'QuicksandBook';
  --font-family-typewriter: 'Typewriter';

  --input-small-height: 32px;
  --input-medium-height: 40px;
  --input-large-height: 48px;  
}
:root{
  --input-small: var(--input-small-height);
  --input-medium: var(--input-medium-height);
  --input-large: var(--input-large-height);
}
