@import '../../../assets/_generic/all';
@import '../../../assets/_shared/custom_table';

.container {
  color: var(--text-color-black);
}

.bodyColumnWrapper {
  padding-top: 30px;
  max-height: calc(100vh - 85px);
  padding-bottom: 60px;
}

.bodyColumn {
  gap: toRem(20px);
  overflow: hidden auto;
}