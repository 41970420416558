@import "../../../assets/_generic/all";

.container {
  color: var(--text-color-black);
}

.header {
  width: 100%;
  height: 100%;
  max-height: toRem(80px);
  background-color: var(--color-primary);
  padding: 24px 18px;
  // border: 0.25px solid var(--color-white);
  border-radius: toRem(4px);
  box-sizing: border-box;
}

.backButton{
  cursor: pointer;
}

.backIcon{
  font-size: toRem(16px);
  margin-top: -2px;
  font-weight: 400;
  width: max-content;
}

.title{
  position: relative;
  color: var(--text-color-black);
  font-weight: 600;
  width: max-content;
  margin-left: 50%;
  transform: translateX(calc(-50% - 50px));
}