h1 {
  font-weight: 600;
  @include fonts(64, 80);
}

h2 {
  font-weight: 600;
  @include fonts(48, 64);
}

h3 {
  font-weight: 600;
  @include fonts(32, 48);
}

h4 {
  font-weight: 600;
  @include fonts(24, 36);
}

h5 {
  font-weight: 600;
  @include fonts(20, 32);
}

h6 {
  font-weight: 600;
  @include fonts(18, 28);
}

.subtitle1 {
  font-weight: 600;
  @include fonts(18, 24);
}

.subtitle2 {
  font-weight: 600;
  @include fonts(14, 24);
}

.body1 {
  font-weight: 400;
  @include fonts(22, 28);

  @media only screen and (max-width: 1900px) {
    @include fonts(18, 24);
  }
}

.body2 {
  font-weight: 400;
  @include fonts(16, 20);
}

.body3 {
  font-weight: 400;
  @include fonts(18, 24);

  @media only screen and (max-width: 1900px) {
    @include fonts(16, 24);
  }
}

.caption {
  font-weight: 400;
  @include fonts(14, 18);
}

.overline {
  font-weight: 500;
  @include fonts(12, 16);
  text-transform: uppercase;
}
