@import '../../../assets/_generic/all';

.main {
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/images/collaborator-login-bg.webp');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @include centerer;

  @include mb {
    background-image: url('../../../assets/images/collaborator-login-bg-mobile.jpg');
  }
}

.container {
  @include centerer;

  @media only screen and (max-width: 767px) {
    padding: 24px;
  }
}

.title {
  @include fonts(46, 46);
  text-align: center;
  color: var(--color-white);
  font-family: var(--font-family-montreal);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.55);

  &Mobile {
    display: none;
  }

  @media only screen and (max-width: 767px) {
    @include fonts(32, 32);

    display: none;

    &Mobile {
      display: block;
    }
  }
}

.logo {
  margin-bottom: 10px;
  width: 85px;
}

.link {
  a {
    color: var(--color-white);
    background-color: initial;
    border: none;
    padding: 0;
    cursor: pointer;
  }
}

.forgotPasswordForm {
  width: 100%;
  max-width: 445px;
  margin: 0 auto;

  input {
    padding: 13px 20px;
    font-size: 18px;
    line-height: 24px;
  }

  label {
    color: var(--color-white) !important;
  }
}

.loginButton {
  background-color: var(--color-primary);
  padding: 12px 20px;
  font-size: 18px;
  height: 50px !important;
  border-radius: 4px !important;
}