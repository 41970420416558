@import '../../../assets/_generic/all';

.container {
  color: var(--text-color-black);
}

.header {
  width: 100%;
  height: 100%;
  max-height: toRem(80px);
  background-color: var(--color-primary);
  padding: 24px 18px;
  // border: 0.25px solid var(--color-white);
  border-radius: toRem(4px);
  box-sizing: border-box;
}

.counter {
  @include fonts(22, 24);
  font-family: var(--font-family-montreal);
  font-weight: 500;
}

.noteIcon {
  width: toRem(16px);
  height: toRem(16px);
  color: var(--color-white);
  border-radius: 50%;
  background-color: var(--text-color-black);
  @include centerer;
}

.toolPanel {
  gap: 24px;
  color: var(--color-white);
  button {
    width: 120px;
  }
}

.awardedTitle {
  font-weight: 600;
}

.awarded {
  font-family: var(--font-family-montreal);
  @include fonts(24, 24);
  font-weight: 500;
}

.toolPanelWrapper {
  color: var(--color-white);
}

.backButton {
  cursor: pointer;

  div {
    font-weight: 400;
    color: var(--color-primary);
  }
}

.backIcon {
  font-size: toRem(16px);
  margin-top: -2px;
}

.headerColumnWrapper {
  gap: toRem(20px);
}

.spotCounter {
  font-weight: 600;
}

.bodyColumnWrapper {
  max-height: calc(100vh - 390px);
  padding-bottom: 60px;
}

.bodyColumn {
  gap: toRem(20px);
  overflow: hidden auto;
}

.user {
  @include limit-line-number();
  @include centerer;
  justify-content: space-between;
  width: 100%;
}

.awardStatus {
  font-family: var(--font-family);
  color: var(--color-primary);
  text-transform: uppercase;
  @include fonts(18, 24);
}

.tabWrapperTitle {
  font-family: var(--font-family);
  color: #fff;
  text-transform: uppercase;
  @include fonts(14, 20);
  padding-top: 30px;
}

.tabWrapper {
  display: flex;
  align-items: center;

  button {
    position: relative;
    background: transparent;
    color: var(--color-white);
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;

    border: 0;
    padding: 0;
    
    &:not(:last-child) {
      margin-right: 15px;
    }

    cursor: pointer;

    @media only screen and (max-width: 1900px) {
      font-size: 14px;
    }

    @media only screen and (max-width: 1500px) {
      font-size: 13px;
    }

    &.active {
      color: var(--color-primary);
      text-decoration: underline;
    }

    &:not(:last-child) {
      &::after {
        content: '';
        width: 1px;
        height: 20px;
        background-color: #fff;
        
        display: inline-block;
        vertical-align: middle;
        margin-left: 20px;
      }
    }
  }
}