.info span {
  display: block;
}

.value {
  display: block;
}

.videoContainer {
  max-width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
}

.reactPlayer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.spanSubmission {
  font-weight: bold;
}

.submissionFileItem {
  width: 100% !important;
  max-height: 100% !important;
  img {
    width: 100% !important;
    height: 100% !important;
  }
}
