@import '../../../../../assets/_generic/all';

.container {
  gap: toRem(20px);
}

.customSwitch {
  min-width: toRem(65px) !important;
  height: toRem(18px) !important;
  line-height: toRem(18px) !important;
  background: transparent !important;
  border: 1px solid var(--color-primary);
  // opacity: 0.6;

  :global(.ant-switch-handle) {
    height: toRem(12px) !important;
    width: toRem(28px) !important;
    background: var(--color-black);
    border-radius: toRem(25px);
    top: 2px;
    left: 3px;

    &::before {
      background-color: var(--color-primary);
    }
  }

  &:global(.ant-switch-checked) {
    background: var(--color-primary) !important;
    // opacity: 1;

    :global(.ant-switch-handle) {
      left: calc(100% - 30px) !important;
      right: 0;
      &::before {
        background-color: var(--color-black);
      }
    }
  }
}

.leftText,
.rightText {
  min-width: toRem(24px);
}
