@import '../../../assets/_generic/all';
@import '../../../assets/_shared/custom_table';

.container {
  color: var(--text-color-black);
}

.bodyColumnWrapper {
  max-height: calc(100vh - 260px);
}

.bodyColumn {
  gap: toRem(20px);
  overflow: hidden auto;
}

.tabWrapper {
  display: flex;
  align-items: center;
  padding: 30px 10px;

  button {
    position: relative;
    background: transparent;
    color: var(--color-white);
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;

    border: 0;
    padding: 0;
    
    &:not(:last-child) {
      margin-right: 15px;
    }

    cursor: pointer;

    @media only screen and (max-width: 1900px) {
      font-size: 14px;
    }

    @media only screen and (max-width: 1500px) {
      font-size: 13px;
    }

    &.active {
      color: var(--color-primary);
      text-decoration: underline;
    }

    &:not(:last-child) {
      &::after {
        content: '';
        width: 1px;
        height: 20px;
        background-color: #fff;
        
        display: inline-block;
        vertical-align: middle;
        margin-left: 20px;
      }
    }
  }
}