

.tabs {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  box-sizing: border; 
  border: none;
  margin-bottom: 0;
  font-family: var(--font-family);
  &:focus{
    border: none;
  }
  :global(.ant-tabs-nav){
    &::before{
      content: unset
    }
  }
  :global(.ant-tabs-nav-wrap){
    border-bottom: none;
  }
  :global(.ant-tabs-tab-active){
    color: var(--color-white);
  }
  :global(.ant-tabs-ink-bar){
    background-color: var(--color-white);
  }
  :global(.ant-tabs-tab-btn){
    color: var(--color-white) !important;
    text-transform: uppercase;
  }
} 