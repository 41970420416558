@import '../../pages/App.module.scss';

.dropFormContainer {
  width: 100%;
  button {
    width: 100%;
  }
}

.dropActionContainer {
  width: 30%;
}