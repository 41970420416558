@import "../../../../../assets/_generic/mixins";

.col {
  @include fonts(14,24);
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  color: var(--color-white);
  box-sizing: border-box;
}

.small{
  height: toRem(24px);
}

.middle{
  height: toRem(38px);
}

.large{
  height: toRem(45px);
}