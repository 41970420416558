.page {
  min-height: 100vh;
}

.content {
  margin: 16px;

  &Container {
    margin-left: 80px;
    transition: all 0.2s;

    &SiderOpen {
      margin-left: 256px;
    }
  }
}

.footer {
  text-align: center;
}
