@import '../../../../../assets/_generic/all';

.input {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  background: var(--color-white);
  box-sizing: border;
  border: none;
  margin-bottom: 0;
  font-family: var(--font-family);

  &:focus {
    border: none;
  }

  &::placeholder {
    @include fonts(16, 24);
    font-family: var(--font-family);
  }
}
