@import '../../../../../assets/_generic/mixins';

.button {
  @include fonts(18, 24);
  @include centerer;
  font-weight: 600;
  color: var(--color-white);
  background-color: var(--text-color-black);
  border: 0.5px solid var(--color-white);
  border-radius: 4px;
  text-transform: uppercase;

  @media only screen and (max-width: 1900px) {
    @include fonts(16, 24);
  }
}

.small {
  height: toRem(24px);
}

.middle {
  height: toRem(38px);
}

.large {
  height: toRem(45px);
}
