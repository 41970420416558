@import '../../../../../assets/_generic/mixins';

@mixin searchInputStyle {
  :global(.ant-input-affix-wrapper) {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    box-shadow: none;
    color: #000;
  }

  :global(.ant-input-group-addon .ant-btn) {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    box-shadow: none;
    color: #000 !important;
  }
}

.searchInput {
  box-shadow: unset;
  border: 1px solid var(--color-black);
  border-radius: toRem(4px);
  max-width: toRem(230px);
  background-color: transparent;

  input {
    background-color: transparent;
  }

  @include searchInputStyle();

  &:hover {
    border-color: var(--color-black) !important;

    @include searchInputStyle();
  }
}
