[title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[title]:after {
  content: attr(title);
  position: absolute;
  bottom: -1.6em;
  z-index: 99;
  visibility: hidden;
  white-space: nowrap;
  background-color: var(--color-grey-500);
  color: var(--color-black);
  font-size: 90%;
  font-weight: lighter;
  padding: 1px 5px 2px 5px;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  border: 1px solid var(--color-black);
  border-radius: 5px 5px 5px 5px;
}

[title] {
  position: relative;
}