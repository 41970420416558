// TODO: refactor styling remove important

// @import './../../../../../assets/_generic/mixins';

.siderNavigation {
  height: 100%;
  background: initial !important;
  font-family: var(--font-family);

  flex: 0 0 350px;
  max-width: 350px;

  .avatar {
    position: relative;
    margin-bottom: 30px;

    @media only screen and (max-width: 1900px) {
      margin-bottom: 10px;
    }

    img {
      width: 75px;
      height: auto;
      margin-bottom: 15px;

      @media only screen and (max-width: 1900px) {
        width: 60px;
      }
    }

    .nickname {
      font-family: var(--font-family);
      font-size: 24px;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 30px;
      color: var(--color-white);

      @media only screen and (max-width: 1900px) {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }

  .menuWrapper {
    list-style: none;

    overflow: auto;

    &::-webkit-scrollbar {
      width: 4px;
      transition: all ease-in-out 500ms;
    }

    &::-webkit-scrollbar-thumb {
      opacity: 0;
      -webkit-transition: opacity 0.5s 0.5s ease-out;
      transition: opacity 0.5s 0.5s ease-out;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: var(--color-primary);
        opacity: 1;
        -webkit-transition-delay: 0s;
        transition-delay: 0s;
      }
    }

    li {
      padding-bottom: 0;
      list-style: none;
      margin-block: 0;
      margin-inline: 0;

      border-radius: 0;
      margin-bottom: 30px;

      outline: 0 !important;

      @media only screen and (max-width: 1900px) {
        margin-bottom: 15px;
      }

      span {
        padding-bottom: 0;
      }
    }
  }

  div {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    span {
      display: inline-block;
      margin-right: 4px;

      svg {
        width: 15px;
        height: auto;
      }
    }
  }

  ul {
    font-size: 16px;

    li {
      background: initial !important;
      background-color: initial !important;
      transition: none;
      list-style-position: unset;
      list-style-type: none;
      border: none;
      font-family: var(--font-family);

      width: auto;
      height: 24px;
      display: inline-block;
      padding: 0;
      padding-right: 0;

      &:global(.ant-menu-item-disabled) {
        cursor: auto;
      }

      span {
        text-align: left;
        padding-bottom: 3px;

        div {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;

          p {
            margin-bottom: 0;
          }
        }

        &:active {
          div {
            p {
              background-color: var(--color-primary);
              color: var(--color-black);
            }
          }
        }
      }

      &:active {
        background-color: unset !important;
      }

      &:first-child {
        .line {
          display: flex;
          align-items: center;

          svg {
            margin-top: 1%;
          }
        }

        .yellowArrow {
          display: flex;
          align-items: center;

          svg {
            width: 20px;
            height: auto;
          }
        }
      }

      &:hover {
        background-color: initial !important;
      }
    }

    .arrowMenuItem {
      background-color: var(--color-black);
      height: auto;
      font-weight: 400;

      &:hover {
        background-color: var(--color-primary);
        color: var(--color-black);
      }

      p {
        padding: 5px 12px;
        font-size: 22px;
        line-height: 26px;
        color: var(--color-white);
        border-radius: 0;

        @media only screen and (max-width: 1900px) {
          padding: 4px 10px;
          font-size: 18px;
          line-height: 24px;
        }

        &:active {
          background-color: var(--color-primary);
          color: var(--color-black);
        }

        &:hover {
          color: var(--color-black) !important;
        }
      }
    }

    :global(.ant-menu-item) {
      padding-right: 0;
    }

    :global(.ant-menu-item-selected) {
      p {
        background-color: var(--color-primary);
        color: var(--color-black);
      }

      .line {
        display: initial !important;
      }

      .yellowArrow {
        display: initial !important;
      }
    }

    :global(.ant-menu-item) {
      padding-left: 0 !important;
    }

    :global(.ant-menu-item-disabled) {
      p {
        background-color: #000;

        &:hover {
          color: var(--color-white) !important;
        }
      }
    }
  }

  .menuWrapper {
    background-color: initial;

    .menuItem {
      .line {
        display: none;
        cursor: default;

        svg {
          height: 2px;
          width: 185px;
        }
      }

      &.subMenuItem {
        padding-left: 15px;
      }

      .yellowArrow {
        margin-inline-start: 0;
        transition: none;
        display: none;
        cursor: default;

        svg {
          margin-top: 20%;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
