@import '../../../global.scss';

.contactFormWrapper {
  font-family: var(--font-family);
  font-size: 16px;

  .formWrapper {
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;

    :global(.ant-form-item) {
      width: 100%;
      text-align: center;
    }

    .submitBtn {
      @include fonts(16, 24);
      font-weight: 700;
      font-family: var(--font-family);
      color: var(--color-black);
      background-color: var(--color-primary);
      border: none;
      padding: 0 20px !important;
      height: 35px !important;
      border-radius: 2px;
      min-width: 200px;
    
      &:focus {
        border: none;
      }
      
      &:hover {
        color: var(--color-black) !important;
      }
      
      &:disabled {
        background-color: var(--color-disabled);
        color: var(--color-black);
      }
    }

    .textarea {
      background-color: rgb(255 255 255 / 10%);
      color: #fff;
      padding: 15px;
      font-family: var(--font-family);
      resize: none;

      &::placeholder {
        color: #999;
      }
    }
  }
}

.successMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  @include fonts(14, 24);
  color: var(--color-white);

  min-height: 500px;

  span {
    display: block;
    @include fonts(20, 32);
    text-transform: uppercase;
  }
}
