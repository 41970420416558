.pageLayout {
  padding: 90px 20px 0;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-family: var(--font-family);
  background-color: var(--color-black);
  background-image: url('/assets/images/mastercard-layout-bg.webp');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .backIcon {
    max-width: 1200px;
    margin: 0 auto 20px;

    button {
      background: none;
      border: 0;
      padding: 0;
      color: var(--color-white);
      font-family: var(--font-family);
      font-size: 16px;
      line-height: normal;
      font-weight: 400;
      cursor: pointer;

      span {
        font-size: 16px;
        position: relative;
        top: -1px;
        display: inline-block;
      }
    }
  }
}

.pageContent {
  padding: 32px;
  background: rgba(0, 0, 0, 0.85);
  border: 0.5px solid var(--color-white);
  border-bottom: 0;
  color: var(--color-white);

  height: calc(100% - 32px);
  max-width: 1200px;
  margin: 0 auto;
  overflow-y: auto;

  h1 {
    font-family: var(--font-family-montreal);
    text-transform: uppercase;
    font-size: 40px;
    line-height: 40px;
    font-weight: 700;
    letter-spacing: 1px;
    margin: 0 0 10px;
  }

  h2,
  h3,
  h4 {
    font-family: var(--font-family-montreal);
    text-transform: uppercase;
    font-size: 26px;
    line-height: 32px;
    font-weight: 700;
    letter-spacing: 1px;
  }

  a,
  p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-white);
  }

  a:hover {
    color: var(--color-white);
  }
}
