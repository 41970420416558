@import '../../../assets/_generic/all';
@import '../../../assets/_shared/custom_accordion';

.noAssetMessage {
  padding: 120px 20px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  :global(.ant-empty-image) ellipse,
  :global(.ant-empty-image) path {
    fill: #777;
  }

  :global(.ant-empty-description) {
    color: #fff;
    
    @include fonts(18, 24);
  }
}

.scrollableContent {
  overflow-y: auto;
  height: 100%;
  padding-right: 2px;
}