.discountTitle {
  margin-bottom: 24px;
}

.discountItemRow {
  margin-top: 12px;
  margin-bottom: 12px;
}

.discountItemInput {
  width: 160px;
}