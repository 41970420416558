@import './theme';

.layout {
  position: relative;
  height: 100%;
  width: 100%;
  padding-left: 160px;
  padding-right: 80px;
  overflow: hidden;
  font-family: var(--font-family);
  background-color: var(--color-black);
  background-image: url('../../../../assets/images/mastercard-layout-bg.webp');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: var(--font-family);

  padding-top: 90px;

  :global(.ant-layout) {
    font-family: var(--font-family) !important;
  }
}

.sideBar {
  flex: 0 0 350px;
  max-width: 350px;
}

.contentWrapper {
  background: initial;

  .content {
    // Hide
    // overflow: auto;
    width: auto;
    padding: 32px;
    background: rgba(0, 0, 0, 0.85);
    border: 1px dashed var(--color-white);
    border-radius: 4px;
    border-bottom: unset;

    div {
      font-family: var(--font-family);
    }
  }
}

.siderWrapper {
  background-color: initial;
  max-width: 1780px;
  margin: 0 auto;
}
