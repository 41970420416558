@import '../../../assets/_generic/all';

.container {
  color: var(--text-color-black);
}

.logoWrapper {
  margin-right: 35px
}

.profileName {
  @include fonts(24, 30);
  font-family: var(--font-family);
  font-weight: 600;
  text-transform: uppercase;

  height: 180px;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 1900px) {
    @include fonts(20, 28);
    height: 120px;
  }
}

.label {
  flex: 1;
  max-width: toRem(152px);
  margin-top: toRem(12px);
}

.value {
  margin-top: toRem(12px);
}

.copyBtn {
  display: flex;
  align-items: center;

  cursor: pointer;
  @include fonts(18, 24);
  color: #fff;
  font-family: var(--font-family);
  font-weight: 400;

  :global(.ant-typography-copy) {
    color: var(--color-primary);
    font-size: 20px;

    &:active,
    &:focus,
    &:hover {
      color: var(--color-primary);
    }
  }
}

.updatePassword {
  @include fonts(16, 24);
  font-weight: 400;

  button {
    background: none;
    padding: 0;
    border: 0;
    border-radius: 0;
    color: var(--color-primary);
    text-decoration: underline;
    cursor: pointer;
  }
}

.eyeIcon {
  cursor: pointer;
}

.frame {
  padding-top: 8px;
  border-top: 0.75px dashed var(--color-white);
}

.logoHolder {
  border-radius: 100%;
  border: 1px solid rgba($color: #fff, $alpha: .6);
  width: 180px;
  height: 180px;

  @media only screen and (max-width: 1900px) {
    width: 120px;
    height: 120px;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-height: 90px;
  }
}

.formRow {
  margin-bottom: 25px;
}

.submitButton {
  @include fonts(16, 24);
  font-weight: 700;
  font-family: var(--font-family);
  color: var(--color-black);
  background-color: var(--color-primary);
  border: none;
  padding: 0 20px !important;
  height: 35px !important;
  border-radius: 2px;

  &:focus {
    border: none;
  }
  
  &:hover {
    color: var(--color-black) !important;
  }
  
  &:disabled {
    background-color: var(--color-disabled);
    color: var(--color-black);
  }
}