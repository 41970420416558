$baseHref: '../../../';

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap');

@font-face {
  font-family: MyriadPro;
  src: url('../../assets/fonts/MyriadPro-Regular.otf');
  font-display: swap;
}

.font_MyriadPro {
  font-family: MyriadPro;
}

@font-face {
  font-family: Courier;
  src: url('../../assets/fonts/courier.ttf');
  font-display: swap;
}

.font_Courier {
  font-family: Courier;
}

@font-face {
  font-family: Helvetica;
  src: url('../../assets/fonts/Helvetica.ttc');
  font-display: swap;
}

.font_Helvetica {
  font-family: Helvetica;
}

@font-face {
  font-family: HelveticaBold;
  src: url('../../assets/fonts/Helvetica.ttf');
  font-display: swap;
}

.font_Helvetica_Bold {
  font-family: HelveticaBold;
}

.font_Helvetica_Black {
  font-family: Helvetica;
  font-weight: 900;
}

@font-face {
  font-family: AlterEgo;
  src: url('../../assets/fonts/AlterEgo.ttf');
  font-display: swap;
}

.font_AlterEgo {
  font-family: AlterEgo;
}

@font-face {
  font-family: QuicksandRegular;
  src: url('../../assets/fonts/Quicksand-Regular.ttf');
  font-display: swap;
}

.font_Quicksand {
  font-family: QuicksandRegular;
}

@font-face {
  font-family: QuicksandBold;
  src: url('../../assets/fonts/Quicksand-Bold.ttf');
  font-display: swap;
}

.font_Quicksand_Bold {
  font-family: QuicksandBold;
}

@font-face {
  font-family: Typewriter;
  src: url('../../assets/fonts/typewriter.ttf');
  font-display: swap;
}

.font_Typewriter {
  font-family: Typewriter;
}

@font-face {
  font-family: MontrealHeavyTwo;
  src: url('../../assets/fonts/Montreal-Heavy_2.ttf');
  font-display: swap;
}

.font_MontrealHeavyTwo {
  font-family: MontrealHeavyTwo;
}

@font-face {
  font-family: Brandhitc;
  src: url('../../assets/fonts/BRADHITC.TTF');
  font-display: swap;
}

.font_Brandhitc {
  font-family: Brandhitc;
}

@font-face {
  font-family: DKPetitFour;
  src: url('../../assets/fonts/DK-Petit-Four.otf');
  font-display: swap;
}

.font_DKPetitFour {
  font-family: DKPetitFour;
}

@font-face {
  font-family: tweedyEr;
  src: url('../../assets/fonts/tweedy_erc_01.ttf');
  font-display: swap;
}

.font_tweedyEr {
  font-family: tweedyEr;
}

@font-face {
  font-family: PacFont;
  src: url('../../assets/fonts/PAC-FONT.ttf');
  font-display: swap;
}

.font_PACFONT {
  font-family: PacFont;
}

@font-face {
  font-family: CITRUS7;
  src: url('../../assets/fonts/CITRUS7.ttf');
  font-display: swap;
}

.font_DKPetitFour {
  font-family: CITRUS7;
}

@font-face {
    font-family: QuicksandBook;
    src: url('../../assets/fonts/Quicksand_Book.otf');
    font-display: swap;
}

.font_QuicksandBook {
    font-family: QuicksandBook;
  }

@font-face {
    font-family: QuicksandBook;
    src: url('../../assets/fonts/Quicksand-Bold.ttf');
    font-display: swap;
    font-weight: 600;
}

.font_QuicksandBook_bold {
  font-weight: 600;
}


:root{
  --font-quicksand: QuicksandRegular;
}

:root{
  --font-family: var(--font-quicksand);
}